import URLs from '@/urls';
import { EntityBase } from '@/commons/EntityBase.js';
import { isPresent } from '@/util/utils';
import constants from '@/commons/constants';
import utils from '@/util/utils';
import formatters from '@/util/formatters';
import i18n from '@/resources/i18n.js';


export class PossibilityHandlerEntity extends EntityBase {
    apiURLs = {
        listAll: URLs.POSSIBILITY,
        save: URLs.POSSIBILITY,
        update: URLs.POSSIBILITY,
        delete: URLs.POSSIBILITY,
        init: URLs.POSSIBILITY_INIT
    };

    createEntityObj(entity = null) {
        let returnObj = {};

        var today = new Date();

        returnObj.id = isPresent(entity) && isPresent(entity.id) ? entity.id : null;

        returnObj.status = isPresent(entity) && isPresent(entity.status) ? entity.status : constants.DEFAULT_STATUS_KEY;

        returnObj.customer_first_name = isPresent(entity) && isPresent(entity.customer_first_name) ? entity.customer_first_name : '';
        returnObj.customer_last_name = isPresent(entity) && isPresent(entity.customer_last_name) ? entity.customer_last_name : '';
        returnObj.customer_phone = isPresent(entity) && isPresent(entity.customer_phone) ? entity.customer_phone : '';
        returnObj.customer_email = isPresent(entity) && isPresent(entity.customer_email) ? entity.customer_email : '';
        returnObj.customer_zipcode = isPresent(entity) && isPresent(entity.customer_zipcode) ? entity.customer_zipcode : "";
        returnObj.customer_city = isPresent(entity) && isPresent(entity.customer_city) ? entity.customer_city : "";
        returnObj.customer_address = isPresent(entity) && isPresent(entity.customer_address) ? entity.customer_address : "";

        returnObj.loc_zipcode = isPresent(entity) && isPresent(entity.loc_zipcode) ? entity.loc_zipcode : "";
        returnObj.loc_city = isPresent(entity) && isPresent(entity.loc_city) ? entity.loc_city : "";
        returnObj.loc_address = isPresent(entity) && isPresent(entity.loc_address) ? entity.loc_address : "";
        returnObj.loc_parcel_number = isPresent(entity) && isPresent(entity.loc_parcel_number) ? entity.loc_parcel_number : "";
        returnObj.loc_site_type = isPresent(entity) && isPresent(entity.loc_site_type) ? entity.loc_site_type : "";
        returnObj.loc_site_size = isPresent(entity) && isPresent(entity.loc_site_size) ? entity.loc_site_size : "";
        returnObj.loc_site_classification = isPresent(entity) && isPresent(entity.loc_site_classification) ? entity.loc_site_classification : "";
        returnObj.loc_site_water = isPresent(entity) && isPresent(entity.loc_site_water) ? entity.loc_site_water : "";
        returnObj.loc_site_electricity = isPresent(entity) && isPresent(entity.loc_site_electricity) ? entity.loc_site_electricity : "";
        returnObj.loc_site_gas = isPresent(entity) && isPresent(entity.loc_site_gas) ? entity.loc_site_gas : "";
        returnObj.loc_site_sewage = isPresent(entity) && isPresent(entity.loc_site_sewage) ? entity.loc_site_sewage : ""; //szennyvíz
        
        returnObj.planned_building_new_or_expansion = isPresent(entity) && isPresent(entity.planned_building_new_or_expansion) ? entity.planned_building_new_or_expansion : true;
        returnObj.planned_building_has_plan = isPresent(entity) && isPresent(entity.planned_building_has_plan) ? entity.planned_building_has_plan : false;
        returnObj.planned_building_unique_or_type = isPresent(entity) && isPresent(entity.planned_building_unique_or_type) ? entity.planned_building_unique_or_type : true;
        returnObj.planned_building_type_name = isPresent(entity) && isPresent(entity.planned_building_type_name) ? entity.planned_building_type_name : "";
        // returnObj.planned_building_create_date = isPresent(entity) && isPresent(entity.planned_building_create_date) && entity.planned_building_create_date != ""? formatters.formatDate(entity.planned_building_create_date, i18n.t("common.onlyDateDateFormat.HU")) : null;
        returnObj.planned_building_create_date_year = isPresent(entity) && isPresent(entity.planned_building_create_date_year) && entity.planned_building_create_date_year != ""? entity.planned_building_create_date_year : today.getFullYear();
        returnObj.planned_building_create_date_quarter = isPresent(entity) && isPresent(entity.planned_building_create_date_quarter) && entity.planned_building_create_date_quarter != ""? entity.planned_building_create_date_quarter : "";

        returnObj.financing_budget = isPresent(entity) && isPresent(entity.financing_budget) ? entity.financing_budget : "";
        returnObj.financing_own_budget = isPresent(entity) && isPresent(entity.financing_own_budget) ? entity.financing_own_budget : "";
        returnObj.financing_need_loan = isPresent(entity) && isPresent(entity.financing_need_loan) ? entity.financing_need_loan : true;
        returnObj.financing_loan = isPresent(entity) && isPresent(entity.financing_loan) ? entity.financing_loan : "";

        returnObj.remember_datetime_1 = isPresent(entity) && entity.remember_datetime_1 != "" && isPresent(entity.remember_datetime_1) ? formatters.formatDate(entity.remember_datetime_1, i18n.t("common.onlyDateDateFormat.HU")) : "";
        returnObj.remember_text_1 = isPresent(entity) && isPresent(entity.remember_text_1) ? entity.remember_text_1 : "";
        returnObj.remember_datetime_2 = isPresent(entity) && entity.remember_datetime_2 != "" && isPresent(entity.remember_datetime_2) ? formatters.formatDate(entity.remember_datetime_2, i18n.t("common.onlyDateDateFormat.HU")) : "";
        returnObj.remember_text_2 = isPresent(entity) && isPresent(entity.remember_text_2) ? entity.remember_text_2 : "";
        returnObj.remember_datetime_3 = isPresent(entity) && entity.remember_datetime_3 != "" && isPresent(entity.remember_datetime_3) ? formatters.formatDate(entity.remember_datetime_3, i18n.t("common.onlyDateDateFormat.HU")) : "";
        returnObj.remember_text_3 = isPresent(entity) && isPresent(entity.remember_text_3) ? entity.remember_text_3 : "";


        returnObj.comment = isPresent(entity) && isPresent(entity.comment) ? entity.comment : "";

        returnObj.created = isPresent(entity) && isPresent(entity.created) && entity.created != ""? formatters.formatDate(entity.created, i18n.t("common.defaultDateTimeFormat.HU")) : null;

        return returnObj;
    }

    // createEntityObjEdit(entity = null) {
    //     let createEntityObj = this.createEntityObj(entity);

    //     createEntityObj.periods.map(el => {
    //         el.start_time = formatters.formatTime(el.start_time, "HH:mm", "HHmm");
    //         el.stop_time = formatters.formatTime(el.stop_time, "HH:mm", "HHmm");
    //         return el;
    //     });
    //     delete createEntityObj["partner_id"];
    //     delete createEntityObj["bnumber"];
    //     return createEntityObj;
    // }

    listAll() {
        let url = eval(`\`${this.apiURLs.listAll}\``);
        this.log.debug('EntityBase of ' + this.constructor.name + ' - listall [ ' + url + ' ]');
        return utils.apiCaller(constants.HTTP_METHODS.GET, url);
    }

    get(entity, idFieldName = 'id') {
        let url = eval(`\`${this.apiURLs.listAll}\``) + "/" + entity[idFieldName];
        this.log.debug('EntityBase of ' + this.constructor.name + ' - get [ ' + url + ' ]');
        return utils.apiCaller(constants.HTTP_METHODS.GET, url)
    }

    save(entity, idFieldName = 'id') {
        this.log.debug('EntityBase of ' + this.constructor.name + ' - save');
        let url = eval(`\`${this.apiURLs.save}\``);

        return utils.apiCaller(constants.HTTP_METHODS.POST, url, entity);
    }

    update(entity, idFieldName = 'id') {
        this.log.debug('EntityBase of ' + this.constructor.name + ' - update');
        const url = eval(`\`${this.apiURLs.update}\``) + "/" + entity[idFieldName];
        return utils.apiCaller(constants.HTTP_METHODS.PATCH, url, entity)
    }

    delete(entity, idFieldName = 'id') {
        this.log.debug('EntityBase of ' + this.constructor.name + ' - delete');
        this.log.debug(entity);
        const url = eval(`\`${this.apiURLs.delete}\``) + "/" + entity[idFieldName];
        return utils.apiCaller(constants.HTTP_METHODS.DELETE, url)
    }

    init(){
        const url = eval(`\`${this.apiURLs.init}\``);
        this.log.debug('EntityBase of ' + this.constructor.name + ' - get [ ' + url + ' ]');
        return utils.apiCaller(constants.HTTP_METHODS.GET, url)
    }
}
