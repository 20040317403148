import DialogBase from '@/commons/DialogBase';
import { PossibilityHandlerEntity } from './PossibilityHandlerEntity';


export default {
    name: "PossibilityHandler",

    extends: DialogBase,
    components: {

    },
    props: {
        value: {
            type: Object,
            required: false
        },
        entity: {
            type: Object,
            required: false
        }
    },

    data() {
        return {
            entityHandler: new PossibilityHandlerEntity(this.$log, this.$store),
            menu1: false,
            menu2: false,
            menu3: false,
            menuFinishDate: false,
            stepper: 1,
            statuses: [],
            quarters: this.$constants.QUARTERS,
            build_years: [""]
        }
    },
    created() {
        this.entityHandler.init()
            .then(response => {
                this.statuses = response.data.data["status"];
                this.statuses.map(el => {
                    el.value = this.$i18n.t("common.possibility.status." + el.key);
                })
            })
            .catch(error => {
                this.$log.error(error);
            })
            .finally(() => { });


        var today = new Date();
        for (var y = today.getFullYear() + 5; y > today.getFullYear() - 10; y--) {
            this.build_years.push(y);
        }

    },
    methods: {
        clearDialog() {
            this.$nextTick(() =>
                this.$refs.form.resetValidation()
            );
        },
        loadEntityData(entity) {
            this.entityHandler.getEntity(entity).then(entityData => {
                this.editedEntity = this.entityHandler.createEntityObj(entityData);
            });
        },
        initForm() {

        },
    },
    watch: {
        isOpen() {
            if (this.value.open) {
                this.initForm();
                this.stepper = 1;
                if (this.value.edit) {
                    this.loadEntityData(this.entity);
                    this.openEditDialog(this.entity);
                    this.clearDialog();
                } else {
                    this.editedEntity = this.entityHandler.createEntityObj();
                    this.openNewDialog();
                    this.clearDialog();
                }
            }
        },
    },
};
