import utils from '@/util/utils';
import constants from '@/commons/constants';

export class EntityBase {
  apiURLs = {};
  entityItems = [];

  constructor(log, store) {
    this.log = log;
    this.store = store;
  }

  fetchEntities() {
    this.log.debug('EntityBase of ' + this.constructor.name + ' - fetchEntities');
//    this.store.commit('setLoading', true);
    return new Promise((resolve, reject) => {
      this.listAll()
        .then(response => {
          this.afterSuccessedFetchEntities();
          this.entityItems = response.data.data;

          // this.store.commit('setStatusCode', response.status);
          resolve(response.data);
        })
        .catch(error => {
          this.entityItems = [];
          this.log.error(error);
          reject(error);
        })
        .finally(() => {
//          this.store.commit('setLoading', false);
        });
    });
  }

  afterSuccessedFetchEntities() {
  }

  getEntity(entity) {
    this.log.debug('EntityBase - getEntity');
//    this.store.commit('setLoading', true);
    return new Promise((resolve, reject) => {
      this.get(entity)
        .then(response => {
          this.store.commit('app/setStatusCode', response.status);
          resolve(response.data.data);
        })
        .catch(error => {
          this.log.error(error);
          reject(error);
        })
        .finally(() => {
//          this.store.commit('setLoading', false);
        });
    });
  }

  saveEntity(entity, fetchEntities = true) {
    this.log.debug('EntityBase - saveEntity');
    this.log.debug(entity);
//    this.store.commit('setLoading', true);
    return new Promise((resolve, reject) => {
      this.save(entity)
        .then(response => {
//          this.store.commit('setStatusCode', response.status);
          if (fetchEntities === true) {
              this.fetchEntities();
          }
          resolve(response);
        })
        .catch(error => {
          this.log.error(error);
          reject(error);
        })
        .finally(() => {
//          this.store.commit('setLoading', false);
        });
    });
  }

  updateEntity(entity, fetchEntities = true) {
    this.log.debug('EntityBase - updateEntity');
    this.log.debug(entity);
    if (fetchEntities === true) {
//      this.store.commit('setLoading', true);
    }

    return new Promise((resolve, reject) => {
      this.update(entity)
        .then(response => {
          // this.store.commit('setStatusCode', response.data.status);
          if (fetchEntities === true) {
            this.fetchEntities();
          }
          resolve(response);
        })
        .catch(error => {
          this.log.error(error);
//          this.store.commit('setLoading', false);
          reject(error);
        })
        .finally(() => {
//          if (fetchEntities === false) this.store.commit('setLoading', false);
        });
    });
  }

  deleteEntity(entity, fetchEntities = true) {
    this.log.debug('EntityBase - deleteEntity');
    this.log.debug(entity);
//    this.store.commit('setLoading', true);
    return new Promise((resolve, reject) => {
      this.delete(entity)
        .then(response => {
          if (fetchEntities === true) this.fetchEntities();
          resolve(response);
        })
        .catch(error => {
          this.log.error(error);
          reject(error);
        })
        .finally(() => {
//          this.store.commit('setLoading', false);
        });
    });
  }
  listAll() {
    this.log.debug('EntityBase of ' + this.constructor.name + ' - listall [ ' + this.apiURLs.listAll + ' ]');
    return utils.apiCaller(constants.HTTP_METHODS.GET, this.apiURLs.listAll)
  }

  get(entity, idFieldName = 'id') {
    this.log.debug('EntityBase of ' + this.constructor.name + ' - get');
    return utils.apiCaller(constants.HTTP_METHODS.GET, this.apiURLs.listAll + "/" + entity[idFieldName])
  }

  save(entity) {
    this.log.debug('EntityBase of ' + this.constructor.name + ' - save');
    // return utils.apiCaller(constants.HTTP_METHODS.POST, this.apiURLs.save + "/" + entity.id, entity) //fixme: remove it
    return utils.apiCaller(constants.HTTP_METHODS.POST, this.apiURLs.save, entity)
  }

  update(entity) {
    this.log.debug('EntityBase of ' + this.constructor.name + ' - update');
    const url = this.apiURLs.update + "/" + entity.id;
    return utils.apiCaller(constants.HTTP_METHODS.PATCH, url, entity)
  }

  delete(entity) {
    this.log.debug('EntityBase of ' + this.constructor.name + ' - delete');
    this.log.debug(entity);
    const url = this.apiURLs.delete + "/" + entity.id;
    return utils.apiCaller(constants.HTTP_METHODS.DELETE, url)
  }
}
