export default {
    data() {
        return {
            editedEntity: {},
            entityHandler: null,
            needRoles: [],

            styleProps: {
                windowHeight: window.innerHeight,
            }
        };
    },

    created() { },

    watch: {},

    mounted() {
        window.addEventListener('resize', () => {
            this.styleProps.windowHeight = window.innerHeight;
        });
    },

    computed: {
        isDrawer() {
            return this.$parent.$parent.$parent.$parent.drawerState;
        },
        isOpen() {
            return this.value.open;
        },
        hasEditRight() {
            let editRights = this.needRoles.filter(role => role.endsWith(this.$constants.NRG_RIGHTS_EDIT_SUFFIX));
            let hasEditRight = false;
            editRights.forEach(right => {
                hasEditRight = this.hasRight(right);
                if (hasEditRight) return true;
            });
            return hasEditRight;
        },
        computedDialogInnerHeight() {
            return this.styleProps.windowHeight - 400;
        },  
    },

    methods: {
        closeDialog() {
            this.value.edit = false;
            this.value.open = false;
            this.$emit('input', this.value)
        },
        clearDialog() {
            this.$refs.form1.resetValidation();
        },
        openNewDialog() {
            this.$log.debug('DialogBase of ' + this.constructor.name + ' - openNewDialog');
            // this.clearDialog();
            this.editedEntity = this.entityHandler.createEntityObj();
            this.value.edit = false;
            this.value.open = true;
        },
        openEditDialog(entity) {
            //this.clearDialog();
            this.$log.debug('DialogBase of ' + this.constructor.name + ' - openEditDialog');
            this.$log.debug(entity);
            this.editedEntity = this.entityHandler.createEntityObj(entity);
            this.$log.debug(this.editedEntity);
            this.value.edit = true;
            this.value.open = true;
        },
        _saveEditedEntityCreateEntityObj(editedEntity) {
            return this.entityHandler.createEntityObj(editedEntity)
        },
        _saveNewEntityCreateEntityObj(editedEntity) {
            return this.entityHandler.createEntityObj(editedEntity)
        },
        saveNewEntity() {
            this.$log.debug('DialogBase of ' + this.constructor.name + ' - saveNewEntity');
            if (this.$refs.form.validate()) {
                const newEntity = this._saveNewEntityCreateEntityObj(this.editedEntity);

                this.entityHandler
                    .saveEntity(newEntity)
                    .then(response => {
                        this.$store.commit('app/setStatusCode', response.status);
                        this.$store.commit('app/setSnackbarEnabled', true);
                        this.$parent.fetchEntities();
                        this.closeDialog();
                    })
                    .catch(error => {
                        this.$log.error(error);
                    })
                    .finally(() => {
                    });
            } else {
                const invalidField = this.$refs.form.inputs.filter(item => item.valid === false);
                if (invalidField) {
                    this.$nextTick(() => invalidField[0].focus());
                }
                this.$store.commit('app/setStatusCode', 'invalidFormData');
                this.$store.commit('app/setSnackbarEnabled', true);
            }
        },

        saveEditedEntity() {
            this.$log.debug('DialogBase of ' + this.constructor.name + ' - saveEditedEntity');
            if (this.$refs.form.validate()) {
                this.$log.debug(this.editedEntity);
                const editedEntity = this._saveEditedEntityCreateEntityObj(this.editedEntity);

                this.entityHandler
                    .updateEntity(editedEntity, false)
                    .then((response) => {
                        this.$store.commit('app/setStatusCode', response.status);
                        this.$store.commit('app/setSnackbarEnabled', true);
                        this.$parent.fetchEntities();
                        this.closeDialog();
                    })
                    .catch(error => {
                        this.$log.error(error);
                    })
                    .finally(() => { });
            } else {
                const invalidField = this.$refs.form.inputs.filter(item => item.valid === false);
                if (invalidField) {
                    this.$nextTick(() => invalidField[0].focus());
                }
                this.$store.commit('app/setStatusCode', 'invalidFormData');
                this.$store.commit('app/setSnackbarEnabled', true);
            }
        },
        // hasRight(right="") {
        //   return Authentication.hasRight(right);
        // },
        // needRolesForMenu(name) {
        //   return this.$router.options.routes.find(el => el.name == name).meta.requiredRoles;
        // },
    }
};

